// import './config';
export const TENANT_SET = 'TENANT_SET';
export const LANG_SET = 'LANG_SET';
export const LOADING = 'LOADING';
export const CLEAR_DATA = 'CLEAR_DATA';
export const CLEAR_CONTROLS = 'CLEAR_CONTROLS';

export const ADD_NEW_INSURANCE = "ADD_NEW_INSURANCE";
export const ADD_NEW_INSURANCE_ERROR = "ADD_NEW_INSURANCE_ERROR";

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_REFRESH_TOKEN = 'LOGIN_REFRESH_TOKEN';
export const LOG_OUT = 'LOG_OUT';
export const UPDATE_LOCALES = 'UPDATE_LOCALES';

export const FETCH_ERROR = 'FETCH_ERROR';
export const POST_ERROR = 'POST_ERROR';
export const PATCH_ERROR = 'PATCH_ERROR';
export const DELETE_ERROR = 'DELETE_ERROR';

export const POST_SUCCESS = 'POST_SUCCESS';
export const PATCH_SUCCESS = 'PATCH_SUCCESS';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';

export const USERDATA_ANLIEGEN = 'USERDATA_ANLIEGEN';
export const USERDATA_DETAILLIERUNG = 'USERDATA_DETAILLIERUNG';
export const USERDATA_DATENQUELLE = 'USERDATA_DATENQUELLE';
export const USERDATA_GEBURTSDATUM = 'USERDATA_GEBURTSDATUM';
export const USERDATA_GESCHLECHT = 'USERDATA_GESCHLECHT';
export const USERDATA_WOHNORT = 'USERDATA_WOHNORT';
export const USERDATA_AUSGESTELLTAM = 'USERDATA_AUSGESTELLTAM';
export const USERDATA_LOHNSUMME = 'USERDATA_LOHNSUMME';
export const USERDATA_BESCHAFTIGUNGSGRAD = 'USERDATA_BESCHAFTIGUNGSGRAD';
export const USERDATA_SPARGUTHABEN = 'USERDATA_SPARGUTHABEN';
export const USERDATA_VPKONTO = 'USERDATA_VPKONTO';
export const USERDATA_VPKONTO_AMOUNT = 'USERDATA_VPKONTO_AMOUNT';
export const USERDATA_AHVRENTE = 'USERDATA_AHVRENTE';
export const USERDATA_RENTENANTEIL = 'USERDATA_RENTENANTEIL';
export const USERDATA_ARBEITSPENSUM = 'USERDATA_ARBEITSPENSUM';
export const USERDATA_CAPITAL_PURCHASE = 'USERDATA_CAPITAL_PURCHASE';
export const USERDATA_CAPITAL_WITHDRAWAL = 'USERDATA_CAPITAL_WITHDRAWAL';
export const USERDATA_BRIDGINGPENSION = 'USERDATA_BRIDGINGPENSION';
export const USERDATA_CAPITAL_PURCHASE_UPDATE = 'USERDATA_CAPITAL_PURCHASE_UPDATE';
export const USERDATA_CAPITAL_PURCHASE_REMOVE = 'USERDATA_CAPITAL_PURCHASE_REMOVE';
export const USERDATA_ZEITPUNKT = 'USERDATA_ZEITPUNKT';
export const USERDATA_BRUTTOLOHN = 'USERDATA_BRUTTOLOHN';
export const USERDATA_ZEITPUNKT_UPDATE = 'USERDATA_ZEITPUNKT_UPDATE';
export const USERDATA_TEILPENSION_REMOVE = 'USERDATA_TEILPENSION_REMOVE';
export const FETCHED_CALCULATIONS = 'FETCHED_CALCULATIONS';
export const FETCHED_CALCULATIONS_FAILED = 'FETCHED_CALCULATIONS_FAILED';
export const FETCHED_MAX_PURCHASE = 'FETCHED_MAX_PURCHASE';
export const FETCHED_SETTINGS = 'FETCHED_SETTINGS';
export const SET_STEPS_OPENED = 'SET_STEPS_OPENED';
export const FORCE_RECALCULATE = 'FORCE_RECALCULATE';

// Animations

export const animationConfig = () => {
    return {
        startSec: 0,
        easing: "Expo.easeInOut",
        easingStart: window.innerWidth < 897 ? "Circ.easeIn" : "Expo.easeInOut",
        scale: 1,
        stagger: {
            each: 0.2,
            // ease: "Power4.easeInOut"
        }
    }
}


export const months = (lang) => {
    if (lang === "en") {
        return ['January','February','March','April','May','June','July','August','September','October','November','December']
    } else if (lang === "it") {
        return ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    } else if (lang === "fr") {
        return ['février','mars','avril','mai','juin','juillet','aout','janvier']
    } else {
        return ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
    }
}

(window as any).preload = function () {
    const payload = JSON.stringify({"Geburtsdatum":"1958-05-09T12:00:00.000Z","descendingStepsToEnteringIndex":{"0":0},"retirementDateAt65":"2023-05-31T12:00:00.000Z","retirementDateAt64":"2022-05-31T12:00:00.000Z","earliestRetirement":"2016-05-31T12:00:00.000Z","retirementPercentage":[100],"arbeitPensums":[0],"RetirementSteps":["2023-05-31T12:00:00.000Z"],"RetirementStepsInitial":["2023-05-31T12:00:00.000Z"],"RetirementStepsUnsorted":["2023-05-31T12:00:00.000Z"],"Geschlecht":"männlich","AusgestelltAm":"2020-02-18T12:00:00.000Z","Lohnsumme":"95000","Beschaftigungsgrad":"90.15","Sparguthaben":"451000","ahvPensionAmount":"2300","partialPensionLimits":{"isAdditionalStepPossible":true,"minimumActivityRate":0,"maximumActivityRate":100,"minimumActivityRateStepSize":5,"possibleRetirementStepList":[{"defaultActivityRate":0,"minimumRetirementPercentageStepSize":50,"minimumRetirementPercentage":50,"maximumRetirementPercentage":50,"defaultRetirementPercentage":50,"startDate":"2020-02-29T00:00:00","endDate":"2023-04-30T00:00:00"}]}})
    localStorage.setItem("userDataWI", payload);
};

(window as any).getTranslations = function () {
    let translations = {};
    document.querySelectorAll("[data-translate]").forEach((el) => {
        let key = (el as any).dataset.translate;
        console.log('keysplit', key.split('.'), 'textContent: ', (el as any).textContent.length);
        if (key.split('.')[1]) {
            translations[key.split('.')[0]] = translations[key.split('.')[0]] || {};
            translations[key.split('.')[0]][key.split('.')[1]] = (el as any).textContent.length ? (el as any).textContent : (el as any).getAttribute("placeholder");
        } else {
            translations[key] = (el as any).textContent.length ? (el as any).textContent : (el as any).getAttribute("placeholder");
        }
    })
    return translations;
};