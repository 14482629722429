import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import '../../Wunschberechnung/Anliegen/Wunschberechnung.scss';
import doc from '../../../assets/imgs/AHV-2.png';
import { Input } from '../../../components/Input/index';
import Modal from 'react-modal';
import SelectList from "../../../components/Sidebar/SelectList";
import { Button, EButtonType } from "../../../components/Button";
import close from "../../../assets/icons/Close_Icon.svg";
// import { formatNumber } from "../../../utils/react";
import gsap from 'gsap';
import { animationConfig } from '../../../constants';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';
import { isNumericOrDot } from '../../../utils/variableEvaluation';
const translatableStrings = defineMessages({
    example: {
        id: 'pageLohnsumme.example',
        defaultMessage: 'Beispiel'
    },
    exampleOfInsurance: {
        id: 'pageLohnsumme.exampleOfInsurance',
        defaultMessage: 'Beispiel Versicherungsausweis'
    },
    next: {
        id: 'button.next',
        defaultMessage: 'WEITER'
    },
    annualSalary: {
        id: 'pageLohnsumme.annualSalary',
        defaultMessage: 'Wie hoch ist der gemeldete AHV-Jahreslohn?'
    },
    howToFind: {
        id: 'pageLohnsumme.howToFind',
        defaultMessage: 'WO FINDE ICH DIE HOHE DER BESOLDUNG?'
    },
    enterSalary: {
        id: 'pageLohnsumme.enterSalary',
        defaultMessage: 'Besoldung eingeben'
    }
})
interface State {
    value: any;
    modalIsOpen: boolean;
    // isFocused: boolean;
}

interface Props {
    actions: any;
    history: any;
    setUserDataLohnsumme: any;
    userDataWI: any;
    intl: any;
}

class Lohnsumme extends React.Component<Props, State> {
    constructor(props: any) {

        super(props);
        this.state = {
            modalIsOpen: false,
            value: this.props.userDataWI.Lohnsumme ? this.props.userDataWI.Lohnsumme : "",
            // isFocused: false
        };
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
    }
    private timeline = null;

    componentDidMount() {
        // if (window.innerWidth > 896) {
        setTimeout(() => {
            (document.querySelector('.main input') as any) && (document.querySelector('.main input') as any).focus();
        }, 1400);
        // }
    }


    async setUserDataLohnsumme(data) {
        await this.props.setUserDataLohnsumme(data);
    }

    async next(e) {
        // let parts = e.toString().split(".");
        // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        // await this.setUserDataLohnsumme(parts.join("."));
        await this.setUserDataLohnsumme(e);

        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (document.querySelectorAll('.crossFade').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.crossFade'), { autoAlpha: 1 }, {
                    autoAlpha: 0
                })
        }

        if (document.querySelectorAll('.stagger').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.stagger'), { autoAlpha: 1 }, {
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                    autoAlpha: 0
                })
        }

        this.timeline.play();

        setTimeout((e) => {
            this.props.history.push({
                pathname: `/beschaftigungsgrad`
            })
        }, 1200)
    }

    start = (e) => {
        this.props.history.push({
            pathname: e
        })
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id
            })
        }
    }

    handleChange(name, value) {
        // console.log(e.currentTarget.value);
        // const value = e.currentTarget.value.replace(/[^.\d]+/g, "").replace(/^([^\.]*\.)|\./g, '$1');
        isNumericOrDot(value) && this.setState({
            value: value
        });
    };

    showHideNotif = () => {
        if (this.state.modalIsOpen && window.innerWidth < 897) {
            this.setState({
                modalIsOpen: false
            })
        } else {
            this.setState({
                modalIsOpen: true
            })
        }
    }

    async showHide(event) {
        event();
    }

    async showHideBack() {
        document.body.classList.remove('blurred');
    }

    async enter(event) {
        if (event.keyCode === 13) {
            this.next(event.currentTarget.value)
        }
    }

    render() {
        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar'>
                    <div className='flex-grow-1'>
                      
                    </div>
                    <div className='selects'>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                    {/* <img alt="logo" src={HelpIcon} className='ques' /> */}
                </div>
                <div className="main lohn-main">
                    <FormattedMessageCustom id={translatableStrings.annualSalary.id} text={this.props.intl.formatMessage(translatableStrings.annualSalary)}>
                        <div className='ich-mochte mb-40 stagger'>
                        </div>
                    </FormattedMessageCustom>
                    {/* <img alt="logo" src={HelpIcon} className='ques_mobile' /> */}
                    <div className='lohnsumme stagger'>
                        <div className='lohnsumme__main'>
                            <Input
                                // onBlur={this.handleBlur}
                                // onFocus={this.handleFocus}
                                value={this.state.value}
                                placeholder={this.props.intl.formatMessage(translatableStrings.enterSalary)}
                                inputWrapperClass="w-100 number_input"
                                onChange={(name, val) => this.handleChange(name, val)}
                                onKeyDown={(event) => this.enter(event)}
                                // type='money'
                                // firefox can't focus on this input when it is on money type
                                type="money"
                                isFocused
                                // validationMessageConfig={{}}
                                inputWrapperStyle={{}}>
                                CHF
                            </Input>
                        </div>
                        <div className='lohnsumme__button'>
                            <Button
                                className="button"
                                submit={true}
                                onClick={() => this.next(this.state.value)}
                                buttonType={EButtonType.FullWidth}
                                // Changed by Glauk, necessary?
                                isDisabled={!this.state.value}
                            >
                                <FormattedMessageCustom id={translatableStrings.next.id} text={this.props.intl.formatMessage(translatableStrings.next)}>
                                    <div></div>
                                </FormattedMessageCustom>
                            </Button>
                        </div>
                    </div>
                    <FormattedMessageCustom id={translatableStrings.howToFind.id} text={this.props.intl.formatMessage(translatableStrings.howToFind)}>
                        <div className="inform stagger" onClick={this.showHideNotif}>
                        </div>
                    </FormattedMessageCustom>
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.showHideNotif}
                        // contentLabel="Large Modal"
                        className={"mobile-modal"}
                        closeTimeoutMS={600}
                        overlayClassName={"large-modal-overlay"}>
                        {this.state.modalIsOpen && <div id='beispiel' className='beispiel' onClick={this.showHideNotif}>
                            <div className="color">
                                <img alt="doc" src={close} className='close' />
                                <div className="modal-document-lohsumme">
                                    <img alt="doc" src={doc} className='document' />
                                    <FormattedMessageCustom id={translatableStrings.exampleOfInsurance.id} text={this.props.intl.formatMessage(translatableStrings.exampleOfInsurance)}>
                                        <div></div>
                                    </FormattedMessageCustom>
                                </div>
                            </div>
                        </div>
                        }
                    </Modal>
                </div>
                <div className='optional crossFade optional-lohnsumme'>
                    <div className='doc'>
                        <img alt="doc" src={doc} />
                        <FormattedMessageCustom id={translatableStrings.example.id} text={this.props.intl.formatMessage(translatableStrings.example)}>
                            <p></p>
                        </FormattedMessageCustom>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataLohnsumme: (data) => dispatch(actions.setUserDataLohnsumme(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Lohnsumme);
