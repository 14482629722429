import * as React from 'react';
import { InputWrapper } from './InputWrapper';
import { InputLabel } from './Label/index';
import { EInputType, InputContent } from './Content/index';
import { renderDefinedTrue } from '../../utils/react';
import { ValidationMessage, ValidationMessageProps } from '../ValidationMessage/index';
import { EValidationTestType } from '../../utils/validationConfigs';

export enum EInputTextType {
    Standard = 'Standard',
    Material = 'Material'
}

export interface IInputConfig {
    // @TODO fix it for radio buttons!
    checkboxLabel?: string;
    checked?: boolean;
    clearable?: boolean;
    customComponent?: JSX.Element;
    defaultChecked?: boolean;
    defaultValidationMessageConfig?: ValidationMessageProps;
    defaultValue?: any;
    disabled?: boolean;
    dropdownLabel?: string;
    forcedValue?: any;
    id?: string;
    inputTextType?: EInputTextType;
    inputType?: EInputType;
    inputWrapperClass?: string;
    inputWrapperStyle?: React.CSSProperties;
    isFocused?: boolean;
    isLoading?: boolean;
    label?: string | JSX.Element;
    max?: number;
    maxLength?: number;
    min?: number;
    name?: string;
    onBlur?: any;
    onFocus?: any;
    onChange?: (name: any, value?: any) => void;
    onDropdownOpen?: (isOpen: boolean) => void;
    onKeyDown?: any;
    placeholder?: string;
    radioOptions?: IInputConfig[];
    readOnly?: boolean;
    selectOptions?: IInputConfig[];
    step?: string;
    style?: any;
    type?: string;
    validationMessageConfig?: ValidationMessageProps;
    validationTests?: EValidationTestType[];
    value?: any;
}
export class Input extends React.Component<IInputConfig, {}> {
    render(): JSX.Element {
        const {
            validationMessageConfig,
            inputWrapperStyle,
            children,
            style,
            inputWrapperClass,
        } = this.props;
        return (
            <InputWrapper inputWrapperStyle={inputWrapperStyle} inputWrapperClass={inputWrapperClass || ""}>
                {renderDefinedTrue(children, () => (
                    <InputLabel style={style || {}}>{children}</InputLabel>
                ))}
                <InputContent inputConfig={this.props} />
                {renderDefinedTrue(validationMessageConfig, () => (
                    <ValidationMessage {...validationMessageConfig} />
                ))}
            </InputWrapper>
        );
    }
}
